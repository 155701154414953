/**
 * Inventory Settings Helper
 * @description Helper class for Inventory Settings
 * shareable methods and properties
 */

import axios from 'axios';

import { baConfig } from '../../../../config';
import { ApiSvcAssetValuationStrategies, ApiSvcImpairmentMethodology } from '../../../../generated/api-svc';

export { ApiSvcAssetValuationStrategies, ApiSvcImpairmentMethodology };

export const taxMethods = {
  2: 'First In, First Out (FIFO)',
  1: 'Last In, First Out (LIFO)',
  4: 'Cost Averaging',
  3: 'Specific Identification (pre-configured rules)',
  5: 'Selected Specific Identification',
  6: 'Balance Only',
};

export const taxMethodsLabels = (enableSelectedSpecId: boolean) => {
  const labels = {
    'First In, First Out (FIFO)': 'FIFO',
    'Last In, First Out (LIFO)': 'LIFO',
    'Cost Averaging': 'CostAverage',
    'Specific Identification (pre-configured rules)': 'SpecificIdentification',
    'Balance Only': 'BalanceOnly',
  } as any;

  if (enableSelectedSpecId) {
    labels['Selected Specific Identification'] = 'SelectedSpecificIdentification';
  }

  return labels;
};

export const allStrategies = [
  'Historical Cost Basis (No Impairment)',
  'Mark to Market - No FMV Rollback',
  'GAAP Impairment',
  'IFRS Impairment',
  'IFRS Impairment (Revalued)',
  'GAAP Fair Value',
];

export const impairmentStrategies = {
  'historical-cost': 'Historical Cost',
  impairment: 'GAAP Impairment',
  'mark-to-market': 'Mark to Market - No FMV Rollback',
  'impairment-reversal-non-revalued': 'IFRS Impairment',
  'impairment-reversal-revalued': 'IFRS Impairment (Revalued)',
  'gaap-fair-value': 'GAAP Fair Value',
};

export const impairmentMethodologies = {
  'Daily Close': ApiSvcImpairmentMethodology.DailyClose,
  'Daily Low': ApiSvcImpairmentMethodology.DailyLow,
  'Daily Open': ApiSvcImpairmentMethodology.DailyOpen,
  'Org Default': ApiSvcImpairmentMethodology.OrgDefault,
  'Monthly Close': ApiSvcImpairmentMethodology.MonthlyClose,
  'Monthly Low': ApiSvcImpairmentMethodology.MonthlyLow,
  'Monthly Open': ApiSvcImpairmentMethodology.MonthlyOpen,
};

export const impairmentMethodologiesKeys = {
  [ApiSvcImpairmentMethodology.DailyClose]: 'Daily Close',
  [ApiSvcImpairmentMethodology.DailyLow]: 'Daily Low',
  [ApiSvcImpairmentMethodology.DailyOpen]: 'Daily Open',
  [ApiSvcImpairmentMethodology.OrgDefault]: 'Org Default',
  [ApiSvcImpairmentMethodology.MonthlyClose]: 'Monthly Close',
  [ApiSvcImpairmentMethodology.MonthlyLow]: 'Monthly Low',
  [ApiSvcImpairmentMethodology.MonthlyOpen]: 'Monthly Open',
};

export type AssetValuationStrategiesType = { coin: string; strategy: ApiSvcAssetValuationStrategies };
export type AssetValuationStrategiesTypeConfig = {
  ticker: string;
  currencyId: string;
  assetValuationStrategy: ApiSvcAssetValuationStrategies;
};

// should make this global for rest of app
export class InventoryViewHelper {
  /**
   * Get all tax strategies
   * @param featureFlag
   * @returns
   */
  public static valuationStrategiesByFeatureFlag(featureFlag: string) {
    if (featureFlag === 'mark-to-market-no-fmv-rollback') {
      return [
        { key: 'Historical Cost Basis (No Impairment)', value: ApiSvcAssetValuationStrategies.HistoricalCost },
        { key: 'Mark to Market - No FMV Rollback', value: ApiSvcAssetValuationStrategies.MarkToMarket },
        { key: 'GAAP Impairment', value: ApiSvcAssetValuationStrategies.Impairment },
        { key: 'IFRS Impairment', value: ApiSvcAssetValuationStrategies.ImpairmentReversalNonRevalued },
        { key: 'IFRS Impairment (Revalued)', value: ApiSvcAssetValuationStrategies.ImpairmentReversalRevalued },
        { key: 'GAAP Fair Value', value: ApiSvcAssetValuationStrategies.GaapFairValue },
      ];
    } else if (featureFlag === 'impairment') {
      return [
        { key: 'Historical Cost Basis (No Impairment)', value: ApiSvcAssetValuationStrategies.HistoricalCost },
        { key: 'GAAP Impairment', value: ApiSvcAssetValuationStrategies.Impairment },
        { key: 'IFRS Impairment', value: ApiSvcAssetValuationStrategies.ImpairmentReversalNonRevalued },
        { key: 'IFRS Impairment (Revalued)', value: ApiSvcAssetValuationStrategies.ImpairmentReversalRevalued },
        { key: 'GAAP Fair Value', value: ApiSvcAssetValuationStrategies.GaapFairValue },
      ];
    } else {
      return [
        { key: 'Historical Cost Basis (No Impairment)', value: ApiSvcAssetValuationStrategies.HistoricalCost },
        { key: 'GAAP Impairment', value: ApiSvcAssetValuationStrategies.Impairment },
        { key: 'IFRS Impairment', value: ApiSvcAssetValuationStrategies.ImpairmentReversalNonRevalued },
        { key: 'IFRS Impairment (Revalued)', value: ApiSvcAssetValuationStrategies.ImpairmentReversalRevalued },
      ];
    }
  }

  /**
   * Get all valid tickers
   * @param assetValuationStrategies
   * @returns
   */
  public async validateTickers(
    assetValuationStrategies: AssetValuationStrategiesType[]
  ): Promise<{ msg: string; status: boolean; data: AssetValuationStrategiesTypeConfig[] | null }> {
    const promises = [];
    const data: AssetValuationStrategiesTypeConfig[] = [];

    if (assetValuationStrategies?.length > 0) {
      // 1. find all empty coins
      const emptyCoins = assetValuationStrategies.filter((strategy) => strategy.coin === '');
      if (emptyCoins.length > 0) {
        return { msg: `Please enter a coin for ${emptyCoins.length} empty strategies`, status: false, data };
      }

      for (let i = 0; i < assetValuationStrategies.length; i++) {
        const ticker = assetValuationStrategies[i].coin;
        const assetsApiUrl = `${baConfig.addressSvcUrl}/symbols/${ticker}`;
        promises.push(axios.get(assetsApiUrl, { params: { coin: ticker } }));
      }

      // 2. validate all tickers
      const resp = await Promise.all(promises);

      // 3. find all invalid tickers
      const invalidResponses = resp.filter((r) => r.status !== 200 || r.data === undefined);
      if (invalidResponses.length > 0) {
        const invalidTickers = invalidResponses.map((r) => r.config.params?.coin);
        return { msg: `Invalid tickers: ${invalidTickers.join(', ')}`, status: false, data };
      }

      // 4. find all valid tickers
      resp.map((r) => {
        const assetValStrat = assetValuationStrategies.find((s) => s.coin === r.config.params?.coin);
        if (assetValStrat) {
          data.push({
            ticker: r.config.params?.coin,
            currencyId: `COIN.${r.data.coinId}`,
            assetValuationStrategy: assetValStrat.strategy,
          });
        }
      });
    }

    return { msg: 'Valid tickers', status: true, data };
  }
}
